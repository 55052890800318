<!--填空题-->
<template>
  <div class="fill">
    <question-header
        :question="question"
        :title="title"
        :question-index="questionIndex"
        :question-favorite="questionFavorite"
    />

    <div class="pt_12 tibox ta_l fsz_18" id="fillStem" style="overflow-x: scroll">
      <span>{{ questionIndex[ 0 ] }}、</span>
      <template v-for="(item,n) in question['例题']">
        <span :key="n" v-if="item[0]=='str'" class="item">{{ item[ 1 ] }}</span>
        <span :key="n" v-else-if="item[0]=='tex'" class="tex_item" :id="'tex_item_'+n"></span>
        <span :key="n" v-else-if="item[0]=='img'" class="tex_item" :id="'tex_item_'+n">
          <img :src="'data:image/png;base64,'+item[1]" alt="">
        </span>
      </template>
    </div>

    <template v-if="questionStep === SUBMIT_TYPE.TO_SUBMIT">
      <submit-tools @submit="handleSubmit" :tips="[]"/>
    </template>

    <template v-if="questionStep === SUBMIT_TYPE.SUBMITTED">
      <question-summary
          :steps="question['分步'] || []"
          :summary="question['总结']"
          :solution="question['分步解析']"
          @next="handleNextQuestion"
          :question-step="questionStep"
      />

      <div class="h16"/>
      <test-site :site="question['考点']" has-title border/>
    </template>

    <van-dialog v-model="tipVisible" @confirm="handleSubmitTip" show-cancel-button>
      <p class="tip-title">请补充答案</p>
      <div class="tip-content" id="tipDialogRef" ref="tipDialogRef" />
    </van-dialog>
  </div>
</template>

<script type="application/javascript">
import { Toast } from 'vant';
import TestSite from '../TestSite';
import QuestionSummary from '../QuestionSummary'
import QuestionHeader from '../QuestionHeader';
import SubmitTools from '../SubmitTools';

import questionMixin from '@/mixins/questionMixin';
import submitMixin from '@/mixins/submitMixin';
import { SUBMIT_TYPE } from '@/consts';

import katex from 'katex';

export default {
  components: {TestSite, QuestionSummary, QuestionHeader, SubmitTools},
  mixins: [questionMixin, submitMixin],
  props: {
    questionFavorite: {
      type: Array,
      default: []
    },
    question: {
      type: Object,
      default: {}
    },
    title: {
      type: String,
      default: ''
    },
    questionIndex: {
      type: Array,
      default: [0, 0]
    }
  },
  data () {
    return {
      SUBMIT_TYPE,
      tipVisible: false,
      questionStep: SUBMIT_TYPE.TO_SUBMIT,
      isRight: 'false',
      curValue: []
    }
  },
  methods: {
    handleSubmit () {
      let isKong= false;
      const inputClass  = $('#fillStem .alertText')[0];
      this.curValue.map((n)=>{
        if(n == '' || n === undefined){
          isKong = true;
        }
      });
      if (isKong || this.curValue.length === 0) {
        Toast('请填写答案!');
        return;
      }

      let result = 'y';
      this.isRight = 'true'

      let answerIndex = -1
      let answerStr = this.question['答案提示'].split('').map(i => {
        if (i === '冇') {
          answerIndex++;
          return `{冇${answerIndex}}`
        }
        return i
      }).join('')
      const divDom = document.createElement('span');
      katex.render(answerStr, divDom, {
        throwOnError: false
      });
      let mcloseArr = $(divDom).find('.cjk_fallback');
      $.each(mcloseArr, (index) => {
        let item = mcloseArr[ index ];
        if ($(item).html() === '冇') {
          const num = $(item).next()[0].innerText
          $(item).next().remove()
          if (this.question['解'][num] == this.curValue[num]) {
            $(item).html('<span class="jie-succ">'+ this.curValue[num] + '</span>')
          } else {
            result = 'n'
            this.isRight = 'false'
            $(item).html('<span class="jie-err">'+ this.curValue[num] +'</span>')
          }
        }
      });
      $(inputClass).hide();
      if (result === 'n') {
        let answerIndex = -1
        let answerStr = this.question['答案提示'].split('').map(i => {
          if (i === '冇') {
            answerIndex++;
            return `{冇${answerIndex}}`
          }
          return i
        }).join('')
        // 渲染正确答案
        const answerDom = document.createElement('span')
        katex.render(answerStr, answerDom, {
          throwOnError: false
        });
        this.$nextTick(() => {
          let mcloseArr = $(answerDom).find('.cjk_fallback');
          $.each(mcloseArr, (index) => {
            let item = mcloseArr[ index ];
            if ($(item).html() === '冇') {
              const num = $(item).next()[0].innerText
              const spanDom = document.createElement('span');
              $(item).html(this.question['解'][num])
              $(item).next().remove()
              $(item).append(spanDom)
            }
          });
        });

        $(inputClass).after(answerDom)
        $(answerDom).before('<span>' + '(' + '</span>')
        $(answerDom).after('<span>' + ')' + '</span>')
      }
      $(inputClass).before(divDom)
      const ret = this.submit({
        exam_status: result,
      });
      if (ret) {
        this.questionStep = SUBMIT_TYPE.SUBMITTED
      }
    },

    // 下一题
    handleNextQuestion () {
      this.$emit('next-question', this.isRight)
    },

    // 提交弹框内容
    handleSubmitTip () {
      const alertClass  = $('#fillStem .alertText')[0];
      const inputClass =  $('#tipDialogRef .inputClass');
      const data = []
      inputClass.map((n,e)=>{
        const [, fillIndex] = e.classList;
        const [, index] = fillIndex.split('-')
        data[index] = e.value.toString()
      });
      let isKong
      data.map((n)=>{
        if(n == '' || n === undefined){
          isKong = true;
        }
      });
      if (isKong || data.length === 0) {
        return;
      }
      this.curValue = data
      let answerIndex = -1
      let answerStr = this.question['答案提示'].split('').map(i => {
        if (i === '冇') {
          answerIndex++;
          return `(冇${answerIndex})`
        }
        return i
      }).join('')
      katex.render(answerStr, alertClass, {
        throwOnError: false
      });
      let index = 0
      let mcloseArr = $('#fillStem').find('.cjk_fallback');
      $.each(mcloseArr, (i) => {
        let item = mcloseArr[ i ];
        if ($(item).html() === '冇') {
          const num = $(item).next()[0].innerText
          $(item).html('' + data[num]);
          $(item).next().remove()
          index++
        }
      });
    },

    renderDialogQuestionStem () {
      let answerIndex = -1
      let answerStr = this.question['答案提示'].split('').map(i => {
        if (i === '冇') {
          answerIndex++;
          return `{冇${answerIndex}}`
        }
        return i
      }).join('')

      katex.render(answerStr, this.$refs.tipDialogRef, {
        throwOnError: false
      });
      this.$nextTick(() => {
        let mcloseArr = $('#tipDialogRef').find('.cjk_fallback');
        $.each(mcloseArr, (index) => {
          let item = mcloseArr[ index ];
          if ($(item).html() === '冇') {
            const num = $(item).next()[0]?.innerText
            const inputDom = document.createElement('input');
            inputDom.className = `inputClass fillIndex-${num}`
            $(item).html('')
            $(item).next().remove()
            $(item).append(inputDom)
          }
        });
      });
    },
  },
  mounted () {
    this.renderQuestionStem(this.question[ '例题' ]);
    this.$nextTick(() => {
      let mcloseArr = $('#fillStem').find('.cjk_fallback');
      $.each(mcloseArr, (index) => {
        let item = mcloseArr[ index ];
        if ($(item).html() === '冇') {
          const spanDom = document.createElement('span');
          spanDom.className = 'alertText'
          spanDom.innerText = '请填写'
          spanDom.onclick = () => {
            this.tipVisible = true
            this.$nextTick(() => {
              this.renderDialogQuestionStem()
            });
          }
          $(item).html(spanDom)
        }
      });
    });
  }
}
</script>

<style lang="less" scoped>
.stem {
  text-align: left;
  margin-bottom: 16px;

  /deep/ &-index {
    font-size: 16px;
  }

  /deep/ .katex {
    line-height: 40px;
    font-size: 18px;
  }
}

/deep/ .formula-input, /deep/ .tip-input {
  width: 64px;
  height: 32px;
  background: #FFFFFF;
  border-radius: 2px;
  border: 1px solid #B9BFD3;
  margin-left: 4px;

  &::placeholder {
    font-size: 14px;
    color: #BFC1CC;
    padding: 6px;
  }
}

.h16 {
  height: 16px;
}

.tip-title {
  height: 22px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #2A3155;
  line-height: 22px;
  margin: 24px 0;
}

.tip-content {
  margin-bottom: 24px;
  font-size: 28px;
}
/deep/ .alertText {
  color: #606266;
  padding: 8px;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
}
</style>
